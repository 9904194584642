import './less/main.less'
import 'unfonts.css'

import $ from 'jquery';
window.$ = $;

import Isotope from "isotope-layout";
import Packery from "packery";
import jQueryBridget from 'jquery-bridget';
import Mustache from 'mustache'

import Content from './js/content';

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

let colors = [
  'rgb(238, 50, 70)',
  'rgb(227, 189, 12)',
  'rgb(87, 185, 71)',
  'rgb(81, 186, 234)',
  'rgb(94, 94, 170)',
  'rgb(215, 156, 198)',
]

let blockTemplates = {
  hello: $('#block_hello').html(),
  contact: $('#block_contact').html(),
  fact: $('#block_fact').html(),
  story: $('#block_story').html(),
  post: $('#block_post').html(),
  work: $('#block_work').html(),
  link: $('#block_link').html(),
  colophon: $('#block_colophon').html(),
}

let block = (type, head, content, settings) => {
  return {
    type,
    head,
    content,
    settings
  }
}

let blocks = [

  block('contact', { title: '+41 78 841 27 94' }, { url: 'tel:+41 78 841 27 94' }),
  block('contact', { title: '+1 312 982 1634' }, { url: 'tel:+1 312 982 1634' }),
  block('contact', { title: 'mike@madebymike.ch' }, { url: 'mailto:mike@madebymike.ch' }),

  block('fact', { lede: 'I am a', title: 'power lifter.' }),
  block('fact', { lede: 'I am a', title: 'studied designer.' }),
  block('fact', { lede: 'I am a', title: 'musician.' }),
  block('fact', { lede: 'I am a self-taught', title: ' software engineer.' }),
  block('fact', { lede: 'I am an', title: 'enabler of agency.' }),
  block('fact', { lede: 'I am', title: '36 years old.' }),
  block('fact', { lede: 'I am', title: 'from Chicago.' }),
  block('fact', { lede: 'I live in', title: 'Bern, CH.' }),
  block('fact', { lede: 'I am a', title: 'cat lover.' }),
  block('fact', { lede: 'I am a bit of a', title: 'whiskey snob.' }),
  block('fact', { lede: 'I am', title: 'quite tall.', detail: '6\' 6" / 198cm.'}),
  block('fact', { lede: 'I am a', title: 'typography nut.' }),
  block('fact', { lede: 'I am an', title: 'amateur meteorologist.' }),

  block('story', { title: 'Places I’ve Worked for' },   { story: Content.story.work },         { openHeight: 5 }),
  block('story', { title: 'Places I’ve Studied at' },   { story: Content.story.studies },      { openHeight: 3 }),
  block('story', { title: 'Instruments I play' },       { story: Content.story.instruments },  { openHeight: 2 }),
  block('story', { title: 'Clients I’ve Worked with' }, { story: Content.story.clients },      { openHeight: 4 }),
  block('story', { title: 'Languages I Speak' },        { story: Content.story.languages },    { openHeight: 2 }),
  block('story', { title: 'Technologies I Like' },      { story: Content.story.technologies }, { openHeight: 4 }),
  block('story', { title: 'Awards I’ve won' },          { story: Content.story.awards },       { openHeight: 3 }),

  block('post', { title: Content.blog[36].title, date: Content.blog[36].date, id: Content.blog[36].id }, { post: Content.blog[36].text }),
  block('post', { title: Content.blog[35].title, date: Content.blog[35].date, id: Content.blog[35].id }, { post: Content.blog[35].text }),
  block('post', { title: Content.blog[34].title, date: Content.blog[34].date, id: Content.blog[34].id }, { post: Content.blog[34].text }),
  block('post', { title: Content.blog[33].title, date: Content.blog[33].date, id: Content.blog[33].id }, { post: Content.blog[33].text }),
  block('post', { title: Content.blog[32].title, date: Content.blog[32].date, id: Content.blog[32].id }, { post: Content.blog[32].text }),

  block('work', { title: 'DingDong App' },         { work: Content.work.dingdongApp },   { hasDetail: true, openHeight: 5 }),
  block('work', { title: 'DingDong Admin' },       { work: Content.work.dingdongAdmin }, { hasDetail: true, openHeight: 5 }),
  block('work', { title: 'Rikscha Ingest' },       { work: Content.work.rikschaIngest }, { hasDetail: true, openHeight: 5 }),
  block('work', { title: 'HYLL' },                 { work: Content.work.hyll },          { hasDetail: false, openHeight: 3 }),
  block('work', { title: 'NEEO Pro' },             { work: Content.work.neeoPro },       { hasDetail: true, openHeight: 5 }),
  block('work', { title: 'UBS Hololens' },         { work: Content.work.UBSHololens },   { hasDetail: true, openHeight: 5 }),
  block('work', { title: 'Lindt Game of Chance' }, { work: Content.work.lindt },         { hasDetail: true, openHeight: 5 }),
  block('work', { title: 'LICK' },                 { work: Content.work.lick },          { hasDetail: true, openHeight: 5 }),

  block('link', { title: 'My Blog' }, {url: 'https://in.ject.ch/'}),
  block('link', { title: 'ject.ch', detail: 'Another playground of mine.' }, {url: 'https://ject.ch/'}),
  block('link', { title: 'My Twitter' }, {url: 'https://twitter.com/ghettosoak'}),
  block('link', { title: 'My Github' }, {url: 'https://github.com/ghettosoak'}),
  block('link', { title: 'My CV' }, {url: 'https://madebymike.ch/cv.pdf'}),
  block('link', { title: 'The Last Fiasco', detail: 'One of my bands.' }, {url: 'https://thelastfiasco.ch/'}),
  block('link', { title: 'An Acceptable Risk', detail: 'Another one of my bands.' }, {url: 'https://anacceptablerisk.bandcamp.com/album/long-and-lean-ep'}),

  block('colophon'),
]

import gsap from "gsap";
import Draggable from "gsap/Draggable";
import InertiaPlugin from "gsap/InertiaPlugin"

gsap.registerPlugin(Draggable, InertiaPlugin); 

let meter = 250;

let grid = {};

let _infinite = document.getElementById('infinite');

let $infinite = $('#infinite');
let $plane = $('#plane');
let $blocks = $('.blocks');

let packeries = {};

let makeid = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

let debounceTimer;

let debounce = (callback) => {
  window.clearTimeout(debounceTimer);
  debounceTimer = window.setTimeout(callback, 500);
}

let getBlock = (_block) => {
  let block = Object.assign(_block, {
    id: makeid(6)
  })

  return Mustache.render($(`#block_${_block.type}`).html(), block);
}

let getBlocks = () => {
  shuffle(blocks)

  let theBlocks = [];

  blocks.forEach((_block, _index) => {
    let index = _index.toString().padStart(3, '0')

    let _theBlock = getBlock(_block)
    theBlocks.push($(_theBlock))
  })

  return theBlocks;
}

let fillDirection = (column, vert, hori, isNew, algorithm) => { 
  let $column = $(column)

  let elements = getBlocks();  

  let $direction = $column.find(`.direction[direction="${vert}"]`)
  console.log('FILLDIRECTION', $column, $direction, vert, hori, `${column} .${vert}`, isNew)

  if (
    (column === '#second' && vert === 'down') || 
    column === '#finite'
  ) {
    let hello = getBlock({ type: 'hello', head: { img: Content.hello.img } },)
    elements.unshift(hello)
  }

  $direction.append(elements)

  let _columnId = $column.attr('id');

  console.log('PACKERIES', packeries)

  if (isNew) {

    if (!packeries.hasOwnProperty(_columnId)) {
      packeries[_columnId] = {}
    }

    packeries[_columnId][vert] = new Packery( `${column} .direction[direction="${vert}"]`, {
      itemSelector: '.block',
      gutter: 10,
      originTop: vert === 'down',
      originLeft: hori === 'right',
      containerStyle: null,
      resize: false,
      // transitionDuration: '.5s',
      transitionDuration: 0,
      // masonry: {
      //   columnWidth: 250
      // }
    });


  } else {
    elements.forEach((element, index) => {
      packeries[_columnId][vert].appended(element)
    })
  }

  $direction.css({
    top: vert === 'down' ? 10 : -$direction.height(),
  })

  bindOpener();

  return $direction.height();
}

let blockify = async (column, hori, isNew) => {
  return new Promise(async (resolve, reject) => {
    let $c = $(column);

    let columnHeight_up = fillDirection(column, 'up', hori, true)
    let columnHeight_down = fillDirection(column, 'down', hori, true)

    $c.find('.edge').each(function (index) {
      observer.observe(this)
    })

    resolve();
  })
} 

let columnify = (hori, where) => {  
  let columnId = makeid(6);

  let _offset = $plane.offset().top;

  let _middle = $infinite.height() / 2;

  let _top = 0;

  _top = Math.ceil(_offset / 250) * 250;

  _top = _top * -1

  console.log('COLUMNIFYING', hori, _top, _offset, _middle)

  let _column = `
    <div class="column" direction="${hori}" id="${columnId}" style="top: ${_top}px; left: ${where}px;">
      <div class="direction" direction="up">
        <div class="edge hori"></div>
        <div class="edge vert"></div>
      </div>
      <div class="direction" direction="down">
        <div class="edge hori"></div>
        <div class="edge vert"></div>
      </div>
    </div>
  `

  if (hori === 'left') {
    $('#plane').prepend(_column)
  } else {
    $('#plane').append(_column)
  }

  blockify(`#${columnId}`, hori)  
}

let observer;

let spatialize = async () => {

  if ($('body').width() >= 768) {

    Draggable.create('#plane', {
      edgeResistance: 0.65,
      type: 'x,y',
      onDrag: function(event) {
        let topLeft = document.elementFromPoint(0, 0)
        let topRight = document.elementFromPoint(_infinite.clientWidth - 1, 0)
        let bottomLeft = document.elementFromPoint(0, _infinite.clientHeight - 1)
        let bottomRight = document.elementFromPoint(_infinite.clientWidth - 1, _infinite.clientHeight - 1)
        let middle = document.elementFromPoint(_infinite.clientWidth / 2, _infinite.clientHeight / 2)

        let _middle = $infinite.height() / 2;

        let middleOfNowhere = $('#plane').position().top

        let newTopOffset = Math.ceil(middleOfNowhere / 250) * 250;

        newTopOffset = newTopOffset * -1

        if (
          topLeft.id === 'infinite' ||
          bottomLeft.id === 'infinite'
        ) {
          $(middle).parents('.column').prev().css('top', newTopOffset)
        }

        if (
          topRight.id === 'infinite' ||
          bottomRight.id === 'infinite'
        ) {
          $(middle).parents('.column').next().css('top', newTopOffset)
        }
      },
      onDragEnd: function(event) {
        console.log('END', event, $plane.offset().top)
      }
    });

    observer = new IntersectionObserver(
      entries => {
        (entries).forEach((entry) => {
          console.log(entry)

          if (entry.isIntersecting) {

            let $currentColumn = $(entry.target).parents('.column')

            let currentColumnId = $currentColumn.attr('id');
            let currentHori = $currentColumn.attr('direction')
            let currentVert  = $(entry.target).parents('.direction').attr('direction');

            console.log('INTERSECT', entry.target, `#${currentColumnId}`, currentVert, currentHori, true)

            if (entry.target.classList.contains('hori')) {
              console.log('HORIZONTAL')

              fillDirection(`#${currentColumnId}`, currentVert, currentHori, false)

            } else if (entry.target.classList.contains('vert')) {
              console.log('VERTICAL', Math.abs($currentColumn.position().left),  $currentColumn.width())


              let $otherObserver = $(entry.target).parent().siblings().find('.vert')

              observer.unobserve(entry.target)
              observer.unobserve($otherObserver[0])

              let newLeftOffset = Math.abs($currentColumn.position().left) + $currentColumn.width()

              if (currentHori === 'left') newLeftOffset = (newLeftOffset * -1);

              debounce(() => {
                columnify(currentHori, newLeftOffset)
              })
            }
          }
        })
      },
      { 
        root: $infinite[0],
        rootMargin: '500px'
        // threshold: [0, 0.25, 0.5, 0.75, 1] 
      }
    );

    $('.edge').each(function (index) {
      observer.observe(this)
    })

    await blockify('#first', 'left')
    await blockify('#second', 'right')


    bindOpener();

    $('.switcher').on('click', function() {

      if ($('body').attr('dimension') === 'infinite') {
        $('body').attr('dimension', 'finite')
      } else {
        $('body').attr('dimension', 'infinite')      
      }
    })
  } else {
    $('body').attr('dimension', 'finite')

    $('.hamburger').on('click', function() {
      $('body').toggleClass('showingCategories')
    })
  }


  fillDirection('#finite', 'down', 'right', true)

  $('.category').on('click', function() {
    let $that = $(this);
    let category = $that.attr('filter');

    if (
      $that.hasClass('selected') ||
      $that.hasClass('clear')
    ) {
      $('.category').removeClass('selected');
      $('#finite').removeClass('filtering');
      $(`#finite .block`).removeClass('filtered');
    } else {
      $that.addClass('selected').siblings().removeClass('selected');
      $('#finite').addClass('filtering');
      $(`#finite .block`).removeClass('filtered');
      $(`#finite .block.${category}`).addClass('filtered');
    }

    packeries['finite']['down'].layout()
  })

  gsap.set('#plane', {
      x: 70,
      // y: -725
      // x: -$('.space').outerWidth() + 20,
      // y: -$('.space').outerHeight() + 20,
  })

}

let bindOpener = () => {
  let opener = function() {
    let $that = $(this)
    console.log('TOGGLEE', this, $that)

    $that.parents('.block').toggleClass('open')

    let $currentColumn = $that.parents('.column')

    let currentColumnId = $currentColumn.attr('id');

    let currentHori = $currentColumn.attr('direction')

    let $currentVert = $that.parents('.direction')

    let currentVertClass  = $currentVert.attr('direction');

    console.log('RESIZING', currentColumnId, currentVertClass,  $that.parents('.block'))

    packeries[currentColumnId][currentVertClass].fit( $that.parents('.block')[0] )

    $currentVert.css({
      top: currentVertClass === 'down' ? 10 : -$currentVert.height(),
    })
  }

  $('.story, .post, .work').find('.block_type, .block_title, .block_hero').off().on('click', opener)
  $('.colophon').find('.block_type, .block_head').off().on('click', opener)

  $('.block_content_detail_item').off().on('click', function() {
    $(this).toggleClass('expanded');

    if ($('body').width() < 768) {
      packeries['finite']['down'].layout()
    }
  })

  $('.block_closer').off().on('click', opener)
}

spatialize()































